import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function FullStack(props) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="-6.647"
          x2="545.624"
          y1="162.996"
          y2="325.428"
        >
          <stop offset="0" stop-color="#ffdbed" />
          <stop offset="1" stop-color="#dcfdee" />
        </linearGradient>
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="255.984"
          x2="255.984"
          y1="52.566"
          y2="396.556"
        >
          <stop offset=".0028" stop-color="#ff7eb8" stop-opacity="0" />
          <stop offset="1" stop-color="#ff7eb8" stop-opacity=".6" />
        </linearGradient>
        <g>
          <g>
            <path
              d="m505.626 290.983c-11.826-34.61-38.138-60.16-69.852-72.202-16.43-6.239-27.346-21.856-27.587-39.429-.005-.384-.012-.769-.021-1.154-1.507-64.582-55.488-116.33-120.077-115.221-38.094.654-71.788 19.355-92.905 47.92-18.185 24.599-46.49 39.965-77.08 39.949-.898 0-1.797.009-2.7.029-61.917 1.353-114.567 54.478-115.393 116.403-.879 65.932 52.303 119.654 118.032 119.654 50.947 0 94.349-32.277 110.886-77.497 4.327-11.832 17.152-18.174 29.283-14.772.151.042.303.085.455.127 11.099 3.064 18.458 13.64 17.55 25.119-1.217 15.383.581 31.255 5.798 46.796 20.521 61.135 87.188 94.685 148.493 74.68 62.711-20.465 96.379-88.184 75.118-150.402z"
              fill="url(#SVGID_1_)"
            />
            <path
              d="m324 116.631v314.006c0 8.281-6.721 14.992-14.992 14.992h-219.845c-8.271 0-14.992-6.711-14.992-14.992v-314.006c0-8.271 6.721-14.992 14.992-14.992h219.845c8.271 0 14.992 6.721 14.992 14.992z"
              fill="#d2deff"
            />
            <path
              d="m299.546 301.199v-123.916c0-1.656-1.342-2.998-2.998-2.998h-194.925c-1.656 0-2.998 1.342-2.998 2.998v123.914c0 1.656 1.342 2.998 2.998 2.998h194.925c1.656.002 2.998-1.341 2.998-2.996z"
              fill="#9fb0fe"
            />
            <path
              d="m323.999 255.715v157.437h-177.937c-8.274 0-14.99-6.715-14.99-14.99v-142.447z"
              fill="#9fb0fe"
            />
            <path
              d="m324.002 149.776v-33.148c0-8.279-6.711-14.99-14.99-14.99h-219.848c-8.279 0-14.99 6.711-14.99 14.99v33.148z"
              fill="#4369fd"
            />
            <path
              d="m380.897 67.556v314.009c0 8.274-6.715 14.99-14.99 14.99h-219.847c-8.274 0-14.99-6.715-14.99-14.99v-314.009c0-8.274 6.715-14.99 14.99-14.99h219.848c8.274 0 14.989 6.716 14.989 14.99z"
              fill="#9fb0fe"
            />
            <path
              d="m131.07 357.872v23.694c0 8.274 6.715 14.99 14.99 14.99h219.848c8.274 0 14.99-6.715 14.99-14.99v-23.694z"
              fill="#b7c5ff"
            />
            <path
              d="m185.615 52.567h-39.555c-8.274 0-14.99 6.715-14.99 14.99v314.009c0 8.274 6.715 14.99 14.99 14.99h39.555z"
              fill="#fff"
            />
            <path
              d="m380.897 100.704v-33.148c0-8.279-6.711-14.99-14.99-14.99h-219.847c-8.279 0-14.99 6.711-14.99 14.99v33.148z"
              fill="#ff7eb8"
            />
            <path
              d="m411.816 117.648h40.871c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-40.871c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#ff7eb8"
            />
            <path
              d="m411.816 135.65h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-19.869c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#4369fd"
            />
            <path
              d="m411.816 153.652h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-19.869c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#4369fd"
            />
            <path
              d="m411.816 189.656h40.871c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-40.871c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#ff7eb8"
            />
            <path
              d="m411.816 207.659h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-19.869c-2.83 0-5.123 2.294-5.123 5.123 0 2.828 2.294 5.123 5.123 5.123z"
              fill="#4369fd"
            />
            <path
              d="m411.816 225.661h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-19.869c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#4369fd"
            />
            <path
              d="m452.688 161.408h-19.869c-2.83 0-5.123 2.294-5.123 5.123s2.293 5.123 5.123 5.123h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.294-5.123-5.123-5.123z"
              fill="#4369fd"
            />
            <path
              d="m411.816 261.665h40.871c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-40.871c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#ff7eb8"
            />
            <path
              d="m411.816 279.667h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-19.869c-2.83 0-5.123 2.294-5.123 5.123s2.294 5.123 5.123 5.123z"
              fill="#4369fd"
            />
            <path
              d="m411.816 297.669h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.293-5.123-5.123-5.123h-19.869c-2.83 0-5.123 2.294-5.123 5.123 0 2.83 2.294 5.123 5.123 5.123z"
              fill="#4369fd"
            />
            <path
              d="m452.688 233.417h-19.869c-2.83 0-5.123 2.294-5.123 5.123s2.293 5.123 5.123 5.123h19.869c2.83 0 5.123-2.294 5.123-5.123s-2.294-5.123-5.123-5.123z"
              fill="#4369fd"
            />
            <g fill="#fff">
              <path d="m244.026 136.076c-.925-3.821-4.316-6.489-8.247-6.489h-4.892c-3.931 0-7.322 2.668-8.247 6.489l-10.193 42.122c-.649 2.684 1 5.386 3.684 6.036 2.683.651 5.387-1 6.036-3.684l4.493-18.569h13.342l4.493 18.569c.554 2.289 2.601 3.826 4.857 3.826.389 0 .785-.046 1.18-.142 2.684-.649 4.334-3.352 3.685-6.036zm-14.943 15.904 2.999-12.392h2.505l2.999 12.392z" />
              <path d="m285.288 129.587h-17.651c-2.762 0-5.001 2.239-5.001 5.001v44.786c0 2.762 2.239 5.001 5.001 5.001s5.001-2.239 5.001-5.001v-14.623h12.65c4.678 0 8.484-3.806 8.484-8.484v-18.195c-.001-4.679-3.806-8.485-8.484-8.485zm-1.518 25.163h-11.133v-15.162h11.133z" />
              <path d="m316.548 174.669v-35.377c1.955-.679 3.368-2.518 3.368-4.705 0-2.762-2.239-5.001-5.001-5.001h-6.735c-2.762 0-5.001 2.239-5.001 5.001 0 2.187 1.412 4.026 3.368 4.705v35.377c-1.955.679-3.368 2.518-3.368 4.705 0 2.762 2.239 5.001 5.001 5.001h6.735c2.762 0 5.001-2.239 5.001-5.001 0-2.187-1.413-4.026-3.368-4.705z" />
              <path d="m335.93 184.483c-2.762 0-5.001-2.239-5.001-5.001v-.145c0-2.762 2.239-5.001 5.001-5.001s5.001 2.239 5.001 5.001v.145c0 2.762-2.239 5.001-5.001 5.001z" />
            </g>
            <path
              d="m231.19 225.208h-15.733c-2.615 0-4.951 1.912-5.199 4.515-.285 2.987 2.056 5.5 4.984 5.5h15.948c2.766 0 5.008-2.242 5.008-5.008 0-2.765-2.242-5.007-5.008-5.007z"
              fill="#d2deff"
            />
            <path
              d="m291.621 225.208h-37.414c-2.766 0-5.008 2.242-5.008 5.008s2.242 5.008 5.008 5.008h37.629c2.928 0 5.269-2.513 4.984-5.5-.248-2.604-2.584-4.516-5.199-4.516z"
              fill="#97ffd2"
            />
            <path
              d="m267.194 246.174h-51.737c-2.615 0-4.951 1.912-5.199 4.515-.285 2.987 2.056 5.5 4.984 5.5h51.952c2.766 0 5.008-2.242 5.008-5.008 0-2.765-2.242-5.007-5.008-5.007z"
              fill="#d2deff"
            />
            <path
              d="m321.624 246.174h-31.414c-2.766 0-5.008 2.242-5.008 5.008s2.242 5.008 5.008 5.008h31.629c2.929 0 5.269-2.514 4.984-5.5-.248-2.604-2.583-4.516-5.199-4.516z"
              fill="#97ffd2"
            />
            <path
              d="m252.192 267.141h-36.735c-2.615 0-4.951 1.912-5.199 4.515-.285 2.987 2.056 5.5 4.984 5.5h36.951c2.766 0 5.008-2.242 5.008-5.008-.001-2.765-2.243-5.007-5.009-5.007z"
              fill="#d2deff"
            />
            <path
              d="m291.621 267.141h-16.412c-2.766 0-5.008 2.242-5.008 5.008s2.242 5.008 5.008 5.008h16.627c2.928 0 5.269-2.513 4.984-5.5-.248-2.604-2.584-4.516-5.199-4.516z"
              fill="#97ffd2"
            />
            <path
              d="m321.625 288.107h-61.417c-2.766 0-5.008 2.242-5.008 5.008s2.242 5.008 5.008 5.008h61.632c2.928 0 5.269-2.513 4.984-5.5-.249-2.604-2.584-4.516-5.199-4.516z"
              fill="#97ffd2"
            />
            <path
              d="m237.191 288.107h-21.734c-2.615 0-4.951 1.912-5.199 4.515-.285 2.987 2.056 5.5 4.984 5.5h21.949c2.766 0 5.008-2.242 5.008-5.008-.001-2.765-2.243-5.007-5.008-5.007z"
              fill="#d2deff"
            />
            <path
              d="m163.343 230.143c0-2.762-2.239-5-5.001-5s-5.001 2.239-5.001 5v.145c0 2.762 2.239 5.001 5.001 5.001s5.001-2.239 5.001-5.001z"
              fill="#b7c5ff"
            />
            <path
              d="m158.343 288.042c-2.762 0-5.001 2.239-5.001 5.001v.145c0 2.762 2.239 5 5.001 5s5.001-2.239 5.001-5v-.145c-.001-2.762-2.24-5.001-5.001-5.001z"
              fill="#b7c5ff"
            />
            <path
              d="m380.897 67.556v314.009c0 8.274-6.715 14.99-14.99 14.99h-219.847c-8.274 0-14.99-6.715-14.99-14.99v-314.009c0-8.274 6.715-14.99 14.99-14.99h219.848c8.274 0 14.989 6.716 14.989 14.99z"
              fill="url(#SVGID_2_)"
            />
            <path
              d="m112.23 130.78c2.761 0 5-2.238 5-5v-.145c0-2.762-2.239-5-5-5s-5 2.238-5 5v.145c0 2.762 2.239 5 5 5z"
              fill="#2d58e0"
            />
            <path
              d="m169.126 71.563c-2.761 0-5 2.238-5 5v.145c0 2.762 2.239 5 5 5s5-2.238 5-5v-.145c0-2.762-2.239-5-5-5z"
              fill="#fe2c8f"
            />
            <path
              d="m188.438 71.563c-2.761 0-5 2.238-5 5v.145c0 2.762 2.239 5 5 5s5-2.238 5-5v-.145c0-2.762-2.239-5-5-5z"
              fill="#fe2c8f"
            />
            <path
              d="m207.749 71.563c-2.761 0-5 2.238-5 5v.145c0 2.762 2.239 5 5 5s5-2.238 5-5v-.145c0-2.762-2.239-5-5-5z"
              fill="#fe2c8f"
            />
            <path
              d="m460.567 385.584-16.276-4.635c-1.594-6.498-4.159-12.617-7.537-18.186l8.235-14.792c.698-1.258.479-2.83-.538-3.848l-11.124-11.124c-1.018-1.018-2.591-1.236-3.848-.538l-14.792 8.235c-5.568-3.377-11.688-5.943-18.186-7.537l-4.635-16.276c-.395-1.388-1.661-2.343-3.104-2.343h-15.73c-1.443 0-2.708.955-3.104 2.343l-4.635 16.276c-6.498 1.594-12.617 4.159-18.186 7.537l-14.792-8.235c-1.258-.698-2.83-.479-3.848.538l-11.124 11.124c-1.018 1.018-1.237 2.591-.538 3.848l8.235 14.792c-3.377 5.568-5.943 11.688-7.537 18.186l-16.276 4.635c-1.388.395-2.343 1.661-2.343 3.104v15.73c0 1.443.955 2.709 2.343 3.104l16.276 4.635c1.594 6.498 4.159 12.617 7.537 18.186l-8.235 14.792c-.698 1.258-.479 2.83.538 3.848l11.124 11.124c1.018 1.018 2.591 1.237 3.848.538l14.792-8.235c5.568 3.377 11.688 5.943 18.186 7.537l4.635 16.276c.395 1.388 1.661 2.343 3.104 2.343h15.73c1.443 0 2.708-.955 3.104-2.343l4.635-16.276c6.498-1.594 12.617-4.159 18.186-7.537l14.792 8.235c1.258.698 2.83.479 3.848-.538l11.124-11.124c1.018-1.018 1.237-2.591.538-3.848l-8.235-14.792c3.377-5.568 5.943-11.688 7.537-18.186l16.276-4.635c1.388-.395 2.343-1.661 2.343-3.104v-15.73c0-1.443-.955-2.709-2.343-3.104zm-79.67 36.764c-14.246 0-25.796-11.55-25.796-25.796s11.55-25.796 25.796-25.796 25.796 11.55 25.796 25.796-11.55 25.796-25.796 25.796z"
              fill="#ff7eb8"
            />
          </g>
          <g>
            <path
              d="m99.521 65.437c-2.761 0-5-2.238-5-5 0-3.309-2.692-6.001-6.001-6.001-2.761 0-5-2.238-5-5s2.239-5 5-5c3.309 0 6.001-2.692 6.001-6.002 0-2.762 2.239-5 5-5s5 2.238 5 5c0 3.31 2.692 6.002 6.001 6.002 2.761 0 5 2.238 5 5s-2.239 5-5 5c-3.309 0-6.001 2.692-6.001 6.001 0 2.762-2.239 5-5 5z"
              fill="#ff7eb8"
            />
            <path
              d="m427.696 79.557c-1.28 0-2.559-.488-3.536-1.465l-10.996-10.995c-.938-.938-1.464-2.209-1.464-3.535s.527-2.598 1.464-3.535l10.996-10.996c.938-.938 2.209-1.465 3.536-1.465 1.326 0 2.598.527 3.536 1.465l10.995 10.996c1.953 1.952 1.953 5.118 0 7.07l-10.995 10.995c-.977.977-2.256 1.465-3.536 1.465zm-3.925-15.995 3.924 3.924 3.924-3.924-3.924-3.925z"
              fill="#97ffd2"
            />
            <g fill="#6583fe">
              <path d="m468.828 330.557c-2.761 0-5-2.238-5-5 0-3.318-2.699-6.018-6.017-6.018-2.761 0-5-2.238-5-5s2.239-5 5-5c3.318 0 6.017-2.699 6.017-6.017 0-2.762 2.239-5 5-5s5 2.238 5 5c0 3.317 2.699 6.017 6.017 6.017 2.761 0 5 2.238 5 5s-2.239 5-5 5c-3.318 0-6.017 2.699-6.017 6.018 0 2.762-2.239 5-5 5z" />
              <path d="m43.172 172.998c-2.761 0-5-2.238-5-5 0-3.317-2.699-6.017-6.017-6.017-2.761 0-5-2.238-5-5s2.239-5 5-5c3.318 0 6.017-2.699 6.017-6.018 0-2.762 2.239-5 5-5s5 2.238 5 5c0 3.318 2.699 6.018 6.017 6.018 2.761 0 5 2.238 5 5s-2.239 5-5 5c-3.318 0-6.017 2.699-6.017 6.017 0 2.761-2.238 5-5 5z" />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
