import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function FastLearnerSvg(props) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="518.348"
          x2="-75.624"
          y1="21.866"
          y2="591.839"
        >
          <stop offset=".007" stop-color="#9494ff" />
          <stop offset=".992" stop-color="#ffe8dc" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="169.789"
          x2="169.789"
          y1="442.935"
          y2="60.328"
        >
          <stop offset="0" stop-color="#ff4d4d" stop-opacity=".4" />
          <stop offset="1" stop-color="#ff4d4d" stop-opacity="0" />
        </linearGradient>
        <g>
          <path
            d="m512 256c0-27.631-12.504-53.535-34.347-75.837-15.199-15.518-27.854-33.295-37.513-52.751-30.889-62.217-83.554-103.196-143.336-103.196-42.483 0-81.369 20.698-111.403 55.003-19.88 22.707-44.981 40.125-72.988 51.371-67.838 27.242-112.413 73.243-112.413 125.41s44.575 98.168 112.414 125.41c28.007 11.246 53.107 28.664 72.988 51.371 30.034 34.305 68.92 55.003 111.402 55.003 59.782 0 112.447-40.979 143.336-103.196 9.659-19.456 22.314-37.232 37.513-52.751 21.843-22.302 34.347-48.206 34.347-75.837z"
            fill="url(#SVGID_1_)"
          />
          <g>
            <g>
              <g>
                <g fill="#ff6c6c">
                  <path d="m289.993 181.54h-36.203v-76.199h36.203c5.523 0 10 4.477 10 10v56.199c0 5.523-4.477 10-10 10z" />
                  <path d="m289.993 397.921h-36.203v-76.199h36.203c5.523 0 10 4.477 10 10v56.199c0 5.523-4.477 10-10 10z" />
                </g>
                <path
                  d="m335.233 264.101-80.25 100.62v-226.18l80.25 100.62c5.82 7.29 5.82 17.65 0 24.94z"
                  fill="#8383ff"
                />
                <path
                  d="m311.893 264.101-56.91 71.35v-167.64l56.91 71.35c5.82 7.29 5.82 17.65 0 24.94z"
                  fill="#b3b3ff"
                />
                <path
                  d="m262.093 205.154c7.473-10.928 11.85-24.14 11.85-38.377 0-16.19-5.662-31.052-15.1-42.737 3.871-9.391 4.692-20.112 1.595-30.613-7.21-24.443-32.87-38.413-57.313-31.202-15.351 4.528-26.554 16.337-30.988 30.548-3.208-.506-6.495-.776-9.846-.776-34.61 0-62.667 28.057-62.667 62.667 0 4.842.568 9.55 1.607 14.076-21.056 10.102-35.595 31.617-35.595 56.531 0 23.536 12.982 44.03 32.169 54.742-2.214 6.609-3.432 13.674-3.432 21.028 0 31.22 21.626 57.374 50.711 64.341 2.12 11.309 7.539 22.12 16.291 30.871 7.703 7.703 17.005 12.824 26.835 15.394 4.127 17.92 20.167 31.287 39.34 31.287 22.302 0 40.381-18.079 40.381-40.381 0-9.397-3.219-18.037-8.602-24.898 9.039-9.304 14.613-21.991 14.613-35.987 0-14.446-5.936-27.501-15.494-36.876 9.806-15.257 15.494-33.411 15.494-52.894.001-16.927-4.293-32.851-11.849-46.744z"
                  fill="#ffab66"
                />
                <path
                  d="m170.866 403.965c5.413 3.537 11.277 6.098 17.343 7.684 4.127 17.92 20.167 31.287 39.34 31.287 21.829 0 39.601-17.323 40.346-38.97h-97.029z"
                  fill="#ffc599"
                />
                <path
                  d="m147.373 373.965h115.216c7.1-8.845 11.354-20.071 11.354-32.297 0-.571-.025-1.137-.044-1.703h-166.861c9.12 12.508 22.518 21.699 38.046 25.418.544 2.902 1.309 5.771 2.289 8.582z"
                  fill="#ffc599"
                />
                <path
                  d="m176.113 132.281v73.99c0 2.761-2.238 5-5 5h-103.91c4.29-18.8 17.04-34.38 34.03-42.53-1.04-4.53-1.61-9.24-1.61-14.08 0-9.82 2.26-19.11 6.29-27.38h65.2c2.762 0 5 2.239 5 5z"
                  fill="#ff6c6c"
                />
                <g fill="#4d4dff">
                  <path d="m357.755 168.024c0-2.762-2.238-5-5-5h-52.762l-.11 10h52.871c2.763 0 5.001-2.238 5.001-5z" />
                  <path d="m347.431 305.267-25.212-25.212c-2.17 2.537-4.417 5.02-6.601 7.539l24.743 24.743c.975.977 2.256 1.465 3.535 1.465s2.56-.488 3.535-1.465c1.953-1.952 1.953-5.118 0-7.07z" />
                  <path d="m397.755 217.157h-82.537l10 10h72.537c2.762 0 5-2.238 5-5s-2.238-5-5-5z" />
                  <path d="m299.993 126.812h100.762c2.762 0 5-2.238 5-5s-2.238-5-5-5h-100.762z" />
                  <path d="m421.754 338.717h-48.145l41.258-41.258c1.953-1.952 1.953-5.118 0-7.07-1.951-1.953-5.119-1.953-7.07 0l-70.27 70.268h-37.534v10h39.605c1.326 0 2.598-.526 3.535-1.465l20.672-20.672c.421.115.855.196 1.313.196h56.636c2.762 0 5-2.238 5-5s-2.238-4.999-5-4.999z" />
                  <path d="m363.705 72.092h-117.103s8.929 10.993 8.393 10h108.711c2.762 0 5-2.238 5-5-.001-2.762-2.239-5-5.001-5z" />
                  <path d="m363.705 414.424h-97.962c-1.578 3.309-3.223 6.643-4.684 10h102.645c2.762 0 5-2.238 5-5s-2.237-5-4.999-5z" />
                </g>
                <ellipse
                  cx="150.072"
                  cy="188.819"
                  fill="#ff9494"
                  rx="9.772"
                  ry="9.772"
                  transform="matrix(.16 -.987 .987 .16 -60.348 306.708)"
                />
                <g>
                  <path
                    d="m173.344 247.646c-1.409 0-2.81-.593-3.799-1.747-4.886-5.701-11.983-8.971-19.474-8.971-7.489 0-14.588 3.27-19.474 8.972-1.797 2.097-4.953 2.34-7.051.543-2.097-1.798-2.34-4.954-.543-7.051 6.789-7.921 16.654-12.464 27.067-12.464 10.414 0 20.279 4.543 27.067 12.465 1.797 2.097 1.554 5.253-.544 7.05-.941.808-2.098 1.203-3.249 1.203z"
                    fill="#ff8b00"
                  />
                </g>
                <g>
                  <g>
                    <path
                      d="m253.35 181.461c-1.409 0-2.81-.593-3.799-1.747-2.364-2.759-5.799-4.342-9.422-4.342-3.624 0-7.059 1.583-9.423 4.342-1.798 2.097-4.955 2.339-7.05.544-2.098-1.797-2.341-4.953-.544-7.05 4.267-4.98 10.47-7.836 17.017-7.836s12.749 2.855 17.016 7.836c1.797 2.097 1.554 5.253-.544 7.05-.943.807-2.1 1.203-3.251 1.203z"
                      fill="#ff8b00"
                    />
                  </g>
                  <g>
                    <path
                      d="m246.134 175.784c-.277 0-.558-.023-.84-.071-2.724-.461-4.557-3.041-4.096-5.764.215-1.272.324-2.55.324-3.797 0-12.468-10.143-22.611-22.61-22.611-12.468 0-22.61 10.144-22.61 22.611 0 1.246.109 2.525.324 3.8.459 2.723-1.376 5.303-4.1 5.762-2.727.461-5.302-1.377-5.762-4.1-.307-1.822-.463-3.66-.463-5.462 0-17.982 14.629-32.611 32.61-32.611 17.982 0 32.61 14.629 32.61 32.611 0 1.805-.156 3.644-.465 5.465-.411 2.441-2.528 4.167-4.922 4.167z"
                      fill="#ff8b00"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="m195.024 325.633c-2.472 0-4.62-1.832-4.951-4.349-1.607-12.218-12.125-21.432-24.465-21.432s-22.856 9.214-24.464 21.432c-.36 2.737-2.858 4.661-5.609 4.305-2.737-.359-4.665-2.871-4.305-5.609 2.259-17.175 17.038-30.127 34.378-30.127s32.119 12.952 34.379 30.127c.36 2.738-1.567 5.249-4.305 5.609-.221.029-.441.044-.658.044z"
                    fill="#ff8b00"
                  />
                </g>
                <g>
                  <path
                    d="m180.367 345.633c-.665 0-1.341-.133-1.989-.415-2.533-1.101-3.694-4.046-2.594-6.578 5.509-12.68 17.991-20.872 31.8-20.872 13.808 0 26.289 8.192 31.8 20.871 1.101 2.533-.061 5.478-2.593 6.579-2.535 1.103-5.477-.061-6.579-2.593-3.922-9.025-12.804-14.857-22.628-14.857s-18.707 5.832-22.628 14.856c-.818 1.884-2.657 3.009-4.589 3.009z"
                    fill="#ff8b00"
                  />
                </g>
                <g fill="#ff8b00">
                  <circle cx="187.415" cy="256.084" r="6.131" />
                  <path d="m233.04 217.402c0 3.386-2.745 6.131-6.131 6.131s-6.131-2.745-6.131-6.131 2.745-6.131 6.131-6.131 6.131 2.745 6.131 6.131z" />
                  <path d="m222.325 111.472c0 3.386-2.745 6.131-6.131 6.131s-6.131-2.745-6.131-6.131 2.745-6.131 6.131-6.131 6.131 2.745 6.131 6.131z" />
                  <path d="m213.715 380.533c0 3.386-2.745 6.131-6.131 6.131s-6.131-2.745-6.131-6.131 2.745-6.131 6.131-6.131 6.131 2.745 6.131 6.131z" />
                </g>
                <path
                  d="m262.093 205.154c7.473-10.928 11.85-24.14 11.85-38.377 0-16.19-5.662-31.052-15.1-42.737 3.871-9.391 4.692-20.112 1.595-30.613-7.21-24.443-32.87-38.413-57.313-31.202-15.351 4.528-26.554 16.337-30.988 30.548-3.208-.506-6.495-.776-9.846-.776-34.61 0-62.667 28.057-62.667 62.667 0 4.842.568 9.55 1.607 14.076-21.056 10.102-35.595 31.617-35.595 56.531 0 23.536 12.982 44.03 32.169 54.742-2.214 6.609-3.432 13.674-3.432 21.028 0 31.22 21.626 57.374 50.711 64.341 2.12 11.309 7.539 22.12 16.291 30.871 7.703 7.703 17.005 12.824 26.835 15.394 4.127 17.92 20.167 31.287 39.34 31.287 22.302 0 40.381-18.079 40.381-40.381 0-9.397-3.219-18.037-8.602-24.898 9.039-9.304 14.613-21.991 14.613-35.987 0-14.446-5.936-27.501-15.494-36.876 9.806-15.257 15.494-33.411 15.494-52.894.001-16.927-4.293-32.851-11.849-46.744z"
                  fill="url(#SVGID_2_)"
                />
                <ellipse
                  cx="270.042"
                  cy="251.631"
                  fill="#ff6c6c"
                  rx="23.751"
                  ry="23.751"
                  transform="matrix(.16 -.987 .987 .16 -21.596 477.879)"
                />
                <ellipse
                  cx="270.042"
                  cy="251.631"
                  fill="#ff9494"
                  rx="9.772"
                  ry="9.772"
                  transform="matrix(.924 -.383 .383 .924 -75.74 122.495)"
                />
              </g>
              <g>
                <ellipse
                  cx="408.207"
                  cy="121.813"
                  fill="#ff6c6c"
                  rx="12.65"
                  ry="12.65"
                  transform="matrix(.23 -.973 .973 .23 195.866 491.113)"
                />
                <path
                  d="m414.354 121.812c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147 6.147 2.753 6.147 6.147z"
                  fill="#ff9494"
                />
              </g>
              <g>
                <circle cx="363.207" cy="168.025" fill="#6c6cff" r="12.65" />
                <path
                  d="m369.354 168.025c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147c3.395-.001 6.147 2.752 6.147 6.147z"
                  fill="#9494ff"
                />
              </g>
              <g>
                <ellipse
                  cx="408.207"
                  cy="222.157"
                  fill="#6c6cff"
                  rx="12.65"
                  ry="12.65"
                  transform="matrix(.707 -.707 .707 .707 -37.528 353.714)"
                />
                <path
                  d="m414.354 222.157c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147 6.147 2.752 6.147 6.147z"
                  fill="#9494ff"
                />
              </g>
              <g>
                <ellipse
                  cx="432.205"
                  cy="343.717"
                  fill="#6c6cff"
                  rx="12.65"
                  ry="12.65"
                  transform="matrix(.924 -.383 .383 .924 -98.635 191.562)"
                />
                <path
                  d="m438.352 343.717c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147 6.147 2.752 6.147 6.147z"
                  fill="#9494ff"
                />
              </g>
              <g>
                <g>
                  <ellipse
                    cx="411.332"
                    cy="293.924"
                    fill="#ff6c6c"
                    rx="12.65"
                    ry="12.65"
                    transform="matrix(.23 -.973 .973 .23 30.766 626.722)"
                  />
                  <path
                    d="m417.479 293.924c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147 6.147 2.752 6.147 6.147z"
                    fill="#ff9494"
                  />
                </g>
              </g>
              <g>
                <g>
                  <circle cx="345.249" cy="310.615" fill="#6c6cff" r="12.65" />
                  <circle cx="345.249" cy="310.615" fill="#9494ff" r="6.147" />
                </g>
              </g>
              <g>
                <circle cx="374.156" cy="419.424" fill="#ff6c6c" r="12.65" />
                <path
                  d="m380.303 419.424c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147 6.147 2.752 6.147 6.147z"
                  fill="#ff9494"
                />
              </g>
              <g>
                <circle cx="374.156" cy="77.092" fill="#6c6cff" r="12.65" />
                <path
                  d="m380.303 77.092c0 3.395-2.752 6.147-6.147 6.147s-6.147-2.752-6.147-6.147 2.752-6.147 6.147-6.147 6.147 2.752 6.147 6.147z"
                  fill="#9494ff"
                />
              </g>
            </g>
            <g>
              <path
                d="m76.929 101.997c-1.279 0-2.559-.488-3.535-1.464l-11.293-11.292c-.938-.939-1.465-2.21-1.465-3.536s.526-2.598 1.465-3.536l11.293-11.292c1.951-1.951 5.119-1.952 7.07.001l11.292 11.292c1.953 1.952 1.953 5.118 0 7.07l-11.292 11.292c-.976.977-2.256 1.465-3.535 1.465zm-4.222-16.292 4.222 4.221 4.221-4.221-4.221-4.221z"
                fill="#ff9d33"
              />
            </g>
            <g>
              <path
                d="m123.924 69.649c-1.279 0-2.559-.488-3.535-1.464l-11.293-11.292c-.938-.938-1.465-2.21-1.465-3.536s.526-2.598 1.465-3.536l11.293-11.292c1.951-1.951 5.119-1.952 7.07.001l11.292 11.292c1.953 1.952 1.953 5.118 0 7.07l-11.292 11.292c-.976.977-2.256 1.465-3.535 1.465zm-4.222-16.292 4.222 4.221 4.221-4.221-4.221-4.221z"
                fill="#6c6cff"
              />
            </g>
            <g>
              <path
                d="m438.353 455.947c-7.175 0-13.013-5.838-13.013-13.013s5.838-13.012 13.013-13.012 13.012 5.837 13.012 13.012-5.838 13.013-13.012 13.013zm0-16.024c-1.661 0-3.013 1.35-3.013 3.012 0 1.661 1.352 3.013 3.013 3.013s3.012-1.352 3.012-3.013c-.001-1.662-1.351-3.012-3.012-3.012z"
                fill="#ff6c6c"
              />
            </g>
            <g>
              <path
                d="m101.953 425.122c-7.175 0-13.012-5.837-13.012-13.012s5.837-13.012 13.012-13.012 13.012 5.837 13.012 13.012-5.837 13.012-13.012 13.012zm0-16.023c-1.661 0-3.012 1.351-3.012 3.012s1.351 3.012 3.012 3.012 3.012-1.351 3.012-3.012c0-1.662-1.351-3.012-3.012-3.012z"
                fill="#ff6c6c"
              />
            </g>
            <g>
              <path
                d="m426.989 69.26c-7.175 0-13.012-5.838-13.012-13.013s5.837-13.012 13.012-13.012 13.012 5.837 13.012 13.012-5.837 13.013-13.012 13.013zm0-16.025c-1.661 0-3.012 1.35-3.012 3.012 0 1.661 1.351 3.013 3.012 3.013s3.012-1.352 3.012-3.013-1.351-3.012-3.012-3.012z"
                fill="#ff9d33"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
